import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layer from "../components/Layer"
import da from "../js/dynamic_adapt"
import { spollers } from "../js/functions"
import Breadcrumbs from "../components/Breadcrumbs"
import "../css/style.css"
import ContentRenderer from "../components/FaqRender/contentRenderer"
import Seo from "../components/Seo"

const Faq = ({ pageContext, data: { strapiDynamicContent } }) => {
  const url = strapiDynamicContent.page_url
  const allFaq = pageContext.allStrapiFaqGroup.edges.sort((a,b) => a.order_number - b.order_number)
  const breadcrumbItems = [
    {
      name: "Help",
      url: "/help",
    },
    {
      name: pageContext.parentTitle,
      url: "/help",
    },
    {
      name: strapiDynamicContent.page_title,
      url: "#",
    },
  ]

  useEffect(() => {
    da.init()
    spollers()
  }, [])

  return (
    <>
      <Seo title={strapiDynamicContent.page_title}/>
      <Layer>
        <main className="benefits">
          <div className="benefits__container">
            <div className="benefits__empty-cell"></div>
            <Breadcrumbs items={breadcrumbItems} />
            <aside className="benefits__sidebar sidebar-benefits">
              <div
                data-spollers="2560,max"
                className="sidebar-benefits__spoller spollers"
              >
                {allFaq.map(faq => {
                  return (
                    <div className="spollers__item">
                      <button
                        type="button"
                        data-spoller
                        className={
                          faq.node.Title === pageContext.parentTitle
                            ? "spollers__title _active"
                            : "spollers__title"
                        }
                      >
                        {faq.node.Title.toUpperCase()}
                      </button>
                      <nav className="spollers__body">
                        <ul className="spollers__list">
                          {faq.node.dynamic_contents.map(item => {
                            return (
                              <li className="spollers__item">
                                <a
                                  href={`/${item.page_url}/`}
                                  className="spollers__link"
                                >
                                  {item.page_url === url ? (
                                    <span>{item.page_title}</span>
                                  ) : (
                                    item.page_title
                                  )}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                      </nav>
                    </div>
                  )
                })}
              </div>
            </aside>
            <section className="benefits__content content-benefits">
              <div className="content-benefits__body body-benefits">
                <div className="body-benefits__title">{strapiDynamicContent.page_title}</div>
                <ContentRenderer content={strapiDynamicContent.content} />
              </div>
            </section>
          </div>
        </main>
      </Layer>
    </>
  )
}

export default Faq

export const pageQuery = graphql`
  query FaqBySlug($strapi_id: Int!) {
    strapiDynamicContent(strapi_id: { eq: $strapi_id }) {
      page_url
      page_title
      strapi_id
      content {
        ... on STRAPI__COMPONENT_FAQ_CONTENT_CONTENT_TEXT {
          text_content {
            data {
              text_content
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_FAQ_CONTENT_ONE_IMAGE {
          strapi_id
          strapi_component
          image {
            localFile {
              childImageSharp {
                fixed {
                  srcSet
                  src
                }
              }
            }
            alternativeText
          }
        }
        ... on STRAPI__COMPONENT_FAQ_CONTENT_TWO_IMAGES {
          strapi_id
          strapi_component
          first_image {
            localFile {
              childImageSharp {
                fixed {
                  src
                  srcSet
                }
              }
            }
            alternativeText
          }
          second_image {
            localFile {
              childImageSharp {
                fixed {
                  src
                  srcSet
                }
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`
