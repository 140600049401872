import React from "react"
import { Link } from "gatsby"

function Breadcrumbs({ items }) {
  return (
    <div className="benefits__breadcrumbs">
      <div data-da=".content-benefits,999.98,first" className="breadcrumb">
        {items.map(({ name, url }, index) => {
          if (url === "#") return <span key={index}>{name}</span>
          else
            return (
              <Link to={url} key={index}>
                {name}
              </Link>
            )
        })}
      </div>
    </div>
  )
}

export default Breadcrumbs
